<template>
  <v-dialog persistent scrollable width="700" v-model="dialog">
    <v-card>
      <v-card-title> Agendamento </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row class="mt-4">
            <v-col cols="12" md="6">
              <app-date-picker
                v-model="form.start_at_date"
                :rules="startAtDateRules"
                label="Data"
                required
            /></v-col>
            <v-col cols="12" md="3">
              <app-time-picker
                v-model="form.start_at_time"
                :rules="startAtTimeRules"
                label="Hora"
                required
            /></v-col>
            <v-col cols="12" md="3">
              <app-number-field
                v-model="form.sessionDuration"
                label="Duração"
                required
                :precision="0"
                :reverse="false"
                suffix=" min"
            /></v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <app-back-btn @click="dialog = false" />
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
        <app-confirm-btn @click="handleSave()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomerSearch from "../../customers/ui/CustomerSearch.vue";
import SessionMixin from "@/mixins/Session/SessionMixin";
import { parseISO } from "date-fns";
import { customerAlias } from "@/utils/customerAlias";

export default {
  mixins: [SessionMixin],

  components: { CustomerSearch },
  data() {
    return {
      frequency: [
        { value: "weekly", text: "Repetir Semanalmente" },
        { value: "biweekly", text: "Repetir Quinzenalmente" },
        { value: "monthly", text: "Repetir Mensalmente" },
      ],

      form: {
        start_at_date: null,
        start_at_time: null,
        frequency: "weekly",
        sessions: [],
      },
      oldSessions: [],
      baseForm: {
        initial_id: null,
        start_at_date: null,
        start_at_time: null,
        frequency: "weekly",
        sessionDuration: 60,
        sessions: [
          {
            id: null,
            start_at_date: null,
            start_at_time: null,
            end_at_date: null,
            end_at_time: null,
          },
        ],
      },

      dialog: false,
      valid: true,
      startAtDateRules: [
        (v) =>
          !!v ||
          `Por favor, Informe a data em que seu ${customerAlias()}  será atendido!`,
      ],
      startAtTimeRules: [
        (v) =>
          !!v ||
          `Por favor, Informe a hora  em que  seu ${customerAlias()} será atendido!`,
      ],
    };
  },

  created() {
    this.reset();
  },
  computed: {
    company() {
      return this.$store.state.auth.company;
    },
  },

  methods: {
    async open(session_group_id, initial_id) {
      this.reset();

      this.form.initial_id = initial_id;
      await this.showSessionGroup(session_group_id);

      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
      this.$nextTick(async () => {
        if (this.$refs["form"]) {
          await this.$refs.form.reset();
        }
        this.form.sessionDuration = this.company.session_duration_default;
      });
    },

    async showSessionGroup(id) {
      await this.$http.$get("sessions/session-group/" + id).then((response) => {
        this.setParams(response.sessionGroup);
      });
    },

    setParams(data) {
      this.form.frequency = data.frequency;

      this.oldSessions = data.sessions.filter((session) => {
        return (
          session.status == "pending" && session.id >= this.form.initial_id
        );
      });
    },

    calcSessions() {
      let initialDateObj = parseISO(this.form.start_at_date);

      this.form.sessions = this.oldSessions.map((row, index) => {
        let sessionNumber = index;
        let start_at_date_obj = this.addDaysFromFrequency(
          initialDateObj,
          this.form.frequency,
          sessionNumber
        );
        let start_at_date = this.formatDate(start_at_date_obj);

        return {
          id: row.id,
          start_at_date: start_at_date,
          start_at_time: this.form.start_at_time,
        };
      });
    },

    getParams() {
      let form = JSON.parse(JSON.stringify(this.form));

      form.sessions = form.sessions.map((row) => {
        return this.setSessionDates(row, this.form.sessionDuration);
      });

      return form;
    },

    handleSave() {
      if (this.$refs.form.validate()) {
        this.calcSessions();

        this.store();
      }
    },

    store() {
      this.$http
        .store("sessions/reschedule-sessions", this.getParams())
        .then((response) => {
          this.processSaved();
        });
    },

    processSaved() {
      this.$emit("store");
      this.dialog = false;
    },
  },
};
</script>

