<template>
  <div>
    <h3 class="mb-2">Sessões</h3>
    <template v-for="(session, index) in value">
      <div class="d-flex pa-2" :key="index">
        <div style="width: 25%">
          <b class="primary--text d-inline mr-2">{{
            $format.time(session.start_at_time)
          }}</b>
          <br />
          <small>
            {{ $format.time(session.end_at_time) }}
          </small>
        </div>
        <div>
          {{ session.customer.name }}
          <br>
          <small class="text--secondary">(Confirmado)</small>
          <!-- <CustomerLabel :customer="session.customer" /> -->
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import CustomerLabel from "../../customers/sections/CustomerLabel.vue";
export default {
  components: { CustomerLabel },
  props: {
    value: {
      default: [],
    },
  },
};
</script>

<style>
</style>