<template>
  <v-dialog persistent scrollable width="700" v-model="dialog">
    <v-card>
      <v-card-title> Remarcar sessão </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row class="mt-4">
            <v-col cols="12" md="6">
              <app-date-picker
                v-model="form.start_at_date"
                :rules="startAtDateRules"
                label="Data"
                required
            /></v-col>
            <v-col cols="12" md="3">
              <app-time-picker
                v-model="form.start_at_time"
                :rules="startAtTimeRules"
                label="Hora"
                required
            /></v-col>
            <v-col cols="12" md="3">
              <app-number-field
                v-model="form.sessionDuration"
                label="Duração"
                required
                :precision="0"
                :reverse="false"
                suffix=" min"
            /></v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <app-back-btn @click="handleCancel()" />
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
        <app-confirm-btn @click="handleSave()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SessionMixin from "@/mixins/Session/SessionMixin";
import { customerAlias } from "@/utils/customerAlias";
import { fromUnixTime, format } from "date-fns";

export default {
  mixins: [SessionMixin],

  data() {
    return {
      frequency: [
        { value: "weekly", text: "Repetir Semanalmente" },
        { value: "biweekly", text: "Repetir Quinzenalmente" },
        { value: "monthly", text: "Repetir Mensalmente" },
      ],

      form: {
        sessions: [],
      },

      baseForm: {
        start_at_date: null,
        start_at_time: null,
        sessionDuration: 60,
        sessions: [
          {
            id: null,
            start_at_date: null,
            start_at_time: null,
            end_at_date: null,
            end_at_time: null,
          },
        ],
      },

      dialog: false,
      valid: true,
      startAtDateRules: [
        (v) =>
          !!v ||
          `Por favor, Informe a data em que seu ${customerAlias()}  será atendido!`,
      ],
      startAtTimeRules: [
        (v) =>
          !!v ||
          `Por favor, Informe a hora  em que  seu ${customerAlias()} será atendido!`,
      ],
    };
  },

  created() {
    this.reset();
  },
  computed: {
    company() {
      return this.$store.state.auth.company;
    },
  },

  methods: {
    async open(session_id, event = null) {
      this.reset();

      await this.showSession(session_id);

      if (event) {
        let newDate = fromUnixTime(event.start / 1000);

        this.form.start_at_date = format(newDate, "yyyy-MM-dd");
        this.form.start_at_time = format(newDate, "HH:mm");
      }

      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
      this.$nextTick(async () => {
        if (this.$refs["form"]) {
          await this.$refs.form.reset();
        }
        this.form.sessionDuration = this.company.session_duration_default;
      });
    },

    async showSession(id) {
      await this.$http.$get("sessions/session/" + id).then((response) => {
        this.setParams(response.session);
      });
    },

    setParams(data) {
      this.form.sessions[0].id = data.id;
    },

    getParams() {
      let form = JSON.parse(JSON.stringify(this.form));

      form.sessions[0].start_at_date = form.start_at_date;
      form.sessions[0].start_at_time = form.start_at_time;

      form.sessions = form.sessions.map((row) => {
        return this.setSessionDates(row, this.form.sessionDuration);
      });

      return form;
    },

    handleSave() {
      if (this.$refs.form.validate()) {
        this.store();
      }
    },

    store() {
      this.$http
        .store("sessions/reschedule-sessions", this.getParams())
        .then((response) => {
          this.processSaved();
        });
    },

    handleCancel() {
      this.dialog = false;
      this.$emit("store");
    },

    processSaved() {
      this.$emit("store");
      this.dialog = false;
    },
  },
};
</script>

