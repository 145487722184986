<template>
  <v-dialog
    persistent
    scrollable
    width="1200"
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-title>
        <h4>Visualizar documento</h4>
      </v-card-title>
      <v-card-text>
        <v-row class="my-10">
          <v-col>
            <div v-html="customerDocument.text"></div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions id="form-card-footer" class="mt-0 pa-4">
        <v-btn @click="dialog = false" text class="mr-2">Voltar</v-btn>
        <v-btn @click="handleDownload()" class="mr-2" color="primary"
          >Download</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      customerDocument: {},

      dialog: false,
    };
  },

  methods: {
    async open(id) {
      await this.showDocument(id);

      this.dialog = true;
    },

    async showDocument(id) {
      this.$loading.start();
      await this.$http
        .show("customer/customer-documents", id)
        .then((response) => {
          this.customerDocument = response.document;

          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    handleDownload() {
      this.$http
        .download("customer/customer-documents-download", {
          id: this.customerDocument.id,
        })

        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "download.pdf");
          document.body.appendChild(link);
          link.click();

          this.$loading.finish();
          this.dialog = false;
        })
        .catch((error) => {
          this.dialog = false;

          this.$loading.finish();
        });
    },
  },
};
</script>


<style>
</style>