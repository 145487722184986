<template>
  <div style="height: 100%">
    <v-row>
      <v-col class="d-none d-md-block" md="2">
        <v-date-picker
          v-model="focus"
          :full-width="true"
          no-title
          :events="daysWithEvents"
          event-color="primary"
          @update:picker-date="handleDatePickerChange($event)"
        ></v-date-picker>
        <v-checkbox
          :value="1"
          hide-details
          color="primary"
          label="Sessões"
        ></v-checkbox>
        <v-checkbox
          :value="1"
          hide-details
          color="success"
          label="Aniversários"
        ></v-checkbox>
        <v-checkbox
          :value="1"
          hide-details
          color="red"
          label="Feriados"
        ></v-checkbox>
      </v-col>
      <v-col md="10" cols="12">
        <v-row>
          <v-col class="d-flex" cols="12" md="6" order="3" order-md="1">
            <v-btn
              class="mr-2"
              fab
              text
              small
              color="grey darken-2"
              @click="prev"
            >
              <v-icon> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn
              class="mr-2"
              fab
              text
              small
              color="grey darken-2"
              @click="next"
            >
              <v-icon> mdi-chevron-right </v-icon>
            </v-btn>
            <h2 v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </h2>
          </v-col>
          <v-col
            class="d-flex justify-md-end"
            cols="6"
            md="4"
            order="1"
            order-md="2"
          >
            <v-btn outlined class="mr-2" color="secondary" @click="setToday()">
              <app-icon class="mr-1">today</app-icon>
              <span class="d-none d-md-inline">Hoje</span>
            </v-btn>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="secondary" outlined v-bind="attrs" v-on="on">
                  <app-icon class="mr-md-2 mr-0">
                    {{ typeToLabel[type].icon }}
                  </app-icon>
                  <span class="d-none d-md-inline">{{
                    typeToLabel[type].label
                  }}</span>
                  <v-icon class="d-none d-md-inline" right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'month'">
                  <v-list-item-action
                    ><app-icon> calendar_month </app-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Mês </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item @click="type = 'week'">
                  <v-list-item-action
                    ><app-icon>calendar_view_week</app-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Semana </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item @click="type = '4day'">
                  <v-list-item-action
                    ><app-icon> table_chart </app-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> 4 dias </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item @click="type = 'day'">
                  <v-list-item-action
                    ><app-icon> calendar_view_day</app-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Dia </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col cols="6" md="2" order="2" order-md="3">
            <v-btn @click="openSessionForm()" color="primary" block>
              <app-icon class="mr-1">add</app-icon> Nova sessão</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <v-calendar
              locale="pt-BR"
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="events"
              :event-color="getEventColor"
              :event-text-color="getEventTextColor"
              :type="type"
              :first-interval="6"
              interval-height="48"
              interval-count="16"
              :short-intervals="false"
              :event-more="false"
              :event-ripple="false"
              @click:date="handleAddEvent($event)"
              @click:event="handleShowEvent($event)"
              @contextmenu:time="handleAddEvent($event)"
              @change="handleCalendarChange($event)"
              @mousedown:event="startDrag"
              @mousedown:day="startTime"
              @mousedown:time="startTime"
              @mousemove:day="mouseMoveDay"
              @mousemove:time="mouseMove"
              @mouseup:time="endDrag($event)"
              @mouseleave.native="cancelDrag"
              v-touch="{
                left: () => next(),
                right: () => prev(),
              }"
            >
              <template v-slot:day="{ date }">
                <div v-if="!hasEventsOn(date)" style="min-height: 80px"></div>
              </template>
              <template v-slot:interval="{ time }">
                <div
                  v-if="time == '06:00'"
                  style="background-color: #f7f7f7; height: 48px"
                ></div>
              </template>
            </v-calendar>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <StoreSessionForm ref="StoreSessionForm" @store="handleStore()" />
    <SessionDialog ref="SessionDialog" @store="handleStore()" />
    <RescheduleSessionForm ref="RescheduleSessionForm" @store="handleStore()" />
  </div>
</template>

<script>
import axios from "axios";
import AppSearchField from "../../app/ui/AppSearchField";
import SessionsList from "./SessionsList.vue";
import DocumentViewDialog from "../../documents/sections/DocumentViewDialog";
import StoreSessionForm from "@/components/session/form/StoreSessionForm.vue";
import SessionDialog from "@/components/session/sections/SessionDialog.vue";
import RescheduleSessionForm from "../form/RescheduleSessionForm.vue";
import {
  format,
  parse,
  parseISO,
  add,
  sub,
  lastDayOfMonth,
  startOfMonth,
} from "date-fns";

export default {
  components: {
    AppSearchField,
    DocumentViewDialog,
    StoreSessionForm,
    SessionDialog,
    RescheduleSessionForm,
    SessionsList,
  },

  // props: {
  //   value: {},
  // },

  data: () => ({
    params: {
      start_date: null,
      end_date: null,
      start_year: null,
    },

    sessions: [],
    holidays: [],
    birthdays: [],
    //events: [],
    daysWithEvents: [],

    focus: "",

    firstInterval: 6,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,

    type: "week",

    typeToLabel: {
      month: { label: "Mês", icon: "calendar_month" },
      week: { label: "Semana", icon: "calendar_view_week" },
      "4day": { label: "4 dias", icon: "table_chart" },
      day: { label: "Dia", icon: "calendar_view_day" },
    },

    statuses: {
      pending: { color: "#EDEAFC", textColor: "primary" },
      canceled: { color: "#FFEBEB", textColor: "error" },
      missed: { color: "#FFEBEB", textColor: "error" },
      shown: { color: "#EAF6EA", textColor: "success" },
    },

    oldDragEvent: null,
    dragEvent: null,
    dragTime: null,
  }),

  mounted() {
    this.setInitialSatate();

    this.$refs.calendar.checkChange();
  },

  computed: {
    events() {
      var sessionEvents = [];
      var holidaysEvents = [];
      var birthdaysEvents = [];

      if (this.sessions.length > 0) {
        sessionEvents = this.sessions.map((row) => {
          return {
            type: "session",
            session: row,
            name: row.customer.name,
            start: parseISO(row.start_at).getTime(),
            end: parseISO(row.end_at).getTime(),
            color: "#7199C0",
            start_hour: format(parseISO(row.start_at), "HH"),
            start_day: row.start_at_date,
            timed: true,
          };
        });
      }

      if (this.holidays.length > 0) {
        holidaysEvents = this.holidays.map((row) => {
          return {
            type: "holiday",
            name: row.name,
            start: parseISO(row.date),
            color: "#7199C0",
            timed: false,
          };
        });
      }

      if (this.birthdays.length > 0) {
        birthdaysEvents = this.birthdays.map((row) => {
          return {
            type: "birthday",
            name: row.name,
            start: parse(
              `${this.params.start_year}-${row.month}-${row.day}`,
              "yyyy-MM-dd",
              new Date()
            ),
            color: "#7199C0",
            timed: false,
          };
        });
      }

      return [...sessionEvents, ...holidaysEvents, ...birthdaysEvents];
    },
  },

  methods: {
    handleCalendarChange(event) {
      console.log("calendar change...");
      let start = event.start.date;
      let end = event.end.date;
      let start_year = parseISO(start).getFullYear();

      if (this.type == "month") {
        start = format(sub(parseISO(start), { days: 7 }), "yyyy-MM-dd");
        end = format(add(parseISO(end), { days: 7 }), "yyyy-MM-dd");
      }

      this.params.start_date = start;
      this.params.end_date = end;
      this.params.start_year = start_year;

      this.selectSessions();
      this.selectHolidays();
    },

    selectSessions() {
      this.$loading.start();
      this.$http
        .index("sessions/calendar", this.params)
        .then((response) => {
          this.sessions = response.sessions;

          this.birthdays = response.birthdays;

          //this.events = this.parseEvents();

          this.setFirstInterval();

          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    selectHolidays() {
      axios
        .get(
          `https://brasilapi.com.br/api/feriados/v1/${this.params.start_year}`
        )
        .then((response) => {
          this.holidays = response.data;
        });
    },

    handleDatePickerChange(event) {
      let month = parse(event, "yyyy-MM", new Date());

      let params = {};

      params.start = format(startOfMonth(month), "yyyy-MM-dd");
      params.end = format(lastDayOfMonth(month), "yyyy-MM-dd");

      this.$http
        .index("sessions/days-with-session", params)
        .then((response) => {
          this.daysWithEvents = response.days;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setFirstInterval() {
      if (this.events.length > 0) {
        this.events.sort((a, b) => a.start_hour - b.start_hour);
        this.firstInterval = this.events[0].start_hour;
      } else {
        this.firstInterval = 6;
      }
    },

    handleShowEvent({ event }) {
      if (this.type == "month") {
        this.$refs.SessionDialog.open(event.session.id);
      }
    },

    handleAddEvent(event) {
      this.$refs.StoreSessionForm.openFormDate(event.date, event.time);
    },

    openSessionForm(data) {
      this.$refs.StoreSessionForm.open(data);
    },

    getEventName(event) {
      return event.input.patient.name;
    },

    setToday() {
      this.focus = "";
    },

    prev() {
      this.$refs.calendar.prev();
    },

    next() {
      this.$refs.calendar.next();
    },

    hasEventsOn(date) {
      return this.events.some((row) => row.start_day == date);
    },

    handleStore() {
      this.$emit("eventUpdate");
    },

    getEventColor(event) {
      if (event.type == "session") {
        return this.statuses[event.session.status].color;
      }

      if (event.type == "holiday") {
        return "red";
      }

      if (event.type == "birthday") {
        return "green";
      }
    },

    getEventTextColor(event) {
      if (event.type == "session") {
        return this.statuses[event.session.status].textColor;
      }

      if (event.type == "holiday") {
        return "white";
      }

      if (event.type == "birthday") {
        return "white";
      }
    },

    setInitialSatate() {
      if (this.$vuetify.breakpoint.mobile) {
        this.type = "4day";
      }
    },

    startDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event;
        this.oldDragEvent = JSON.parse(JSON.stringify(event));
        //this.oldDragEvent.start = getUnixTime(this.oldDragEvent.start)
        //this.dragTime = null;
        //this.extendOriginal = null;
      }
    },

    startTime(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      }
    },

    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      }
    },

    endDrag(event) {
      if (this.dragEvent) {
        if (this.dragEvent.start != this.oldDragEvent.start) {
          this.rescheduleSession(this.dragEvent);
        } else {
          this.$refs.SessionDialog.open(this.oldDragEvent.session.id);
          this.resetDrag();
        }
      } else {
        this.$refs.StoreSessionForm.openFormDate(event.date, event.time);
      }

      this.resetDrag();
    },

    mouseMoveDay(tms) {},

    cancelDrag() {
      if (this.dragEvent) {
        this.dragEvent.start = this.oldDragEvent.start;
        this.dragEvent.end = this.oldDragEvent.end;

        this.resetDrag();
      }
    },

    async rescheduleSession(event) {
      this.$refs.RescheduleSessionForm.open(event.session.id, event);
    },

    resetDrag() {
      this.oldDragEvent = null;
      this.dragEvent = null;
      this.dragTime = null;
    },

    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },

    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
  },
};
</script>

<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}
</style>