<template>
  <SessionCalendarNew
    
  />
</template>

<script>
import StoreSessionForm from "@/components/session/form/StoreSessionForm.vue";
import SessionCalendarNew from "../components/session/sections/SessionCalendarNew.vue";

export default {
  components: {
    StoreSessionForm,
    SessionCalendarNew,
  },

  data: () => ({
    params: {
      start_date: null,
      end_date: null,
    },

    sessions: [],
  }),

  mounted() {
    this.select();
  },

  watch: {
    params: {
      deep: true,
      handler() {
        this.select();
      },
    },
  },

  methods: {
    openSessionForm() {
      this.$refs.SessionForm.open();
    },

    handleChange(event) {
      this.params.start_date = event.start_date;
      this.params.end_date = event.end_date;
    },

    handleEventUpdate() {
      this.select();
    },

    select() {
      // this.$loading.start();
      // this.$http
      //   .index("sessions/calendar", this.params)
      //   .then((response) => {
      //     this.sessions = response.sessions;
      //     this.$loading.finish();
      //   })
      //   .catch((error) => {
      //     this.$loading.finish();
      //   });
    },
  },
};
</script>

<style></style>
