<template>
  <div>
    <template v-if="sendSessionReminderEnable">
      <template v-if="isErrored">
        <v-icon color="error" small left> mdi-alert-circle </v-icon>
        <small> Falha ao enviar mensagem {{ errorNumber }} </small>
      </template>
      <template v-else>
        <template v-if="!session.status_by_customer">
          <v-icon color="#00A884" small class="mr-1">mdi-whatsapp</v-icon>
          <small style="color: #00a884">
            {{ statuses[notificationStatus].text }}
          </small>
          <v-icon
            class="ml-2"
            v-if="statuses[notificationStatus].statusIcon"
            :color="statuses[notificationStatus].statusIconColor"
            small
          >
            {{ statuses[notificationStatus].statusIcon }}
          </v-icon>
          <small class="ml-1" v-text="errorNumber" />
        </template>
        <template v-if="session.status_by_customer">
          <template v-if="session.status_by_customer == 'confirmed'">
            <v-icon color="#00A884" small class="mr-1">mdi-whatsapp</v-icon>
            <small style="color: #00a884">
              Sessão confirmada pelo {{ customerAlias }}
            </small>
            <v-icon class="ml-2" :color="'info'" small>
              mdi-thumb-up-outline
            </v-icon>
          </template>
          <template v-if="session.status_by_customer == 'reschedule'">
            <v-icon color="warning" small class="mr-1">mdi-alert-circle</v-icon>
            <small style="color: #00a884">
              {{ customerAlias }} solicitou remarcação!
            </small>
            <!-- <br />
          <small> Motivo: {{ session.customer_justification }} </small> -->
          </template>
        </template>
      </template>
    </template>
    <template v-if="!sendSessionReminderEnable">
      <v-icon color="info" small left> mdi-bell-off </v-icon>
      <small> Lembrete de sessão desativado </small>
    </template>
  </div>
</template>

<script>
import { customerAlias } from "@/utils/customerAlias";

export default {
  props: {
    session: {},
  },

  data: () => ({
    statuses: {
      willNotify: {
        text: "A mensagem será enviada 1 dia antes da sessão",
      },
      error: {
        text: "Erro ao enviar mensagem",
        statusIcon: "mdi-alert-circle",
        statusIconColor: "error",
      },
      sending: {
        text: "Enviando mensagem de lembrete da sessão",
        statusIcon: "mdi-clock-outline",
        statusIconColor: "gray",
      },
      pending: {
        text: "Enviando mensagem de lembrete da sessão",
        statusIcon: "mdi-clock-outline",
        statusIconColor: "gray",
      },
      success: {
        text: "Mensagem de lembrete da sessão foi enviada",
        statusIcon: "mdi-check",
        statusIconColor: "gray",
      },
      RECEIVED: {
        text: "Mensagem de lembrete da sessão recebida",
        statusIcon: "mdi-check-all",
        statusIconColor: "gray",
      },
      DELIVERY_ACK: {
        text: "Mensagem de lembrete da sessão recebida",
        statusIcon: "mdi-check-all",
        statusIconColor: "gray",
      },
      READ: {
        text: "Mensagem de lembrete da sessão lida",
        statusIcon: "mdi-check-all",
        statusIconColor: "info",
      },
      SENT: {
        text: "Mensagem de lembrete da sessão enviada",
        statusIcon: "mdi-check-all",
        statusIconColor: "info",
      },
    },

    errors: {
      "Phone number does not exist": "Número de telefone não existe",
      "O número consultado não existe no WhatsApp.":
        " | Número de telefone não existe",
      "Phone is empty": "Whatsapp vazio",
    },
  }),

  computed: {
    customerAlias() {
      return customerAlias();
    },

    notification() {
      return this.session.last_whats_app_notification;
    },

    isNotified() {
      return this.notification != null;
    },

    notificationStatus() {
      if (!this.isNotified) {
        return "willNotify";
      }

      if (this.notification.whatsapp_status) {
        return this.notification.whatsapp_status;
      }

      return this.notification.status;
    },

    hasWhatsappId() {
      return this.notification.whatsapp_id != null;
    },

    isErrored() {
      return this.isNotified && !this.hasWhatsappId;
    },
    errorNumber() {
      var hasResponse = this.notification?.response != null;

      if (hasResponse && JSON.parse(this.notification?.response).error) {
        return this.getErroMessage();
      }
    },

    sendSessionReminderEnable() {
      if (this.session.customer != null) {
        return this.session.customer.send_session_reminder;
      }
      return true;
    },
  },

  methods: {
    getErroMessage() {
      var err = JSON.parse(this.notification?.response);

      if (err.message) {
        return this.errors[err.message];
      }

      if (err.error) {
        return this.errors[err.error];
      }
      return this.errors[err.error];
    },
  },
};
</script>

<style></style>
