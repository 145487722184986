<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="mt-4 text-h5">
          Tem certeza que quer deletar essa sessão?
        </span>
      </v-card-title>
      <v-card-text class="pb-0">
        <!-- <div class="pt-10">
          <h2 class="text-h5">Tem certeza que quer deletar essa sessão?</h2>
        </div> -->
        <div class="mt-6" v-if="isFromGroup">
          <v-switch
            v-model="form.delete_sessions"
            class="pb-0"
            :true-value="1"
            :false-value="0"
            hide-details
            :label="'Deletar também as sessões futuras deste pacote'"
          ></v-switch>
        </div>
        <div class="mt-6">
          <v-switch
            v-if="canDeleteTransactions"
            v-model="form.delete_transactions"
            :true-value="1"
            :false-value="0"
            :label="'Deletar também os registros financeiros?'"
          ></v-switch>
        </div>
      </v-card-text>
      <v-card-actions class="text-right" style="background-color: #f5f5f5">
        <v-btn @click="dialog = false" text> voltar </v-btn>
        <v-btn color="error" @click="handleDelete()"> Deletar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    session: {},
  },

  data: () => ({
    dialog: false,

    form: {
      delete_sessions: 0,
      delete_transactions: 0,
    },
  }),

  computed: {
    isFromGroup() {
      return this.session.session_group.repetitions_count > 1;
    },

    isTransactionFromFormGroup() {
      return this.session.transactions.length == 0;
    },

    canDeleteTransactions() {
      if (this.isTransactionFromFormGroup) {
        return this.form.delete_sessions;
      }

      return true;
    },
  },

  methods: {
    open() {
      this.reset();
      this.dialog = true;
    },

    reset() {
      this.form.delete_sessions = 0;
      this.form.delete_transactions = 0;
    },

    async handleDelete() {
      this.$loading.start();
      await this.$http
        .destroy("sessions/session", this.session.id, this.form)
        .then((response) => {
          this.$loading.finish();
          this.$emit("delete");
          this.dialog = false;
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style></style>
