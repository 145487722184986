<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title> Editar Modalidade de Atendimento </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="mt-1">
          <v-col>
            <v-switch
              small
              class="mt-0"
              v-model="form.is_online_session"
              :true-value="1"
              :false-value="0"
              label="Atendimento online"
              hide-details="auto"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <app-text-field
              v-model="form.video_call_link"
              label="Link"
              required
              :disabled="!form.is_online_session"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <app-back-btn @click="dialog = false" />
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
        <app-confirm-btn @click="handleSave()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    session: {},
  },

  data() {
    return {
      dialog: false,
      form: {},
    };
  },

  watch: {
    "form.is_online_session": function (val) {
      if (val) {
        this.form.video_call_link = this.form.customer.video_call_link ?? null;
      }
    },
    deep: true,
  },

  methods: {
    open() {
      this.reset();
      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.session));
    },

    handleSave() {
      this.$loading.start();
      this.$http
        .update("sessions/session", this.form.id, {
          video_call_link: this.form.video_call_link,
          status: this.form.status,
          color: this.form.color,
          is_online_session: this.form.is_online_session,
        })
        .then((response) => {
          this.$loading.finish();
          this.$emit("store");
          this.dialog = false;
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style>
</style>