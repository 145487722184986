var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('v-row',[_c('v-col',{staticClass:"d-none d-md-block",attrs:{"md":"2"}},[_c('v-date-picker',{attrs:{"full-width":true,"no-title":"","events":_vm.daysWithEvents,"event-color":"primary"},on:{"update:picker-date":function($event){return _vm.handleDatePickerChange($event)}},model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}}),_c('v-checkbox',{attrs:{"value":1,"hide-details":"","color":"primary","label":"Sessões"}}),_c('v-checkbox',{attrs:{"value":1,"hide-details":"","color":"success","label":"Aniversários"}}),_c('v-checkbox',{attrs:{"value":1,"hide-details":"","color":"red","label":"Feriados"}})],1),_c('v-col',{attrs:{"md":"10","cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"6","order":"3","order-md":"1"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1),(_vm.$refs.calendar)?_c('h2',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e()],1),_c('v-col',{staticClass:"d-flex justify-md-end",attrs:{"cols":"6","md":"4","order":"1","order-md":"2"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","color":"secondary"},on:{"click":function($event){return _vm.setToday()}}},[_c('app-icon',{staticClass:"mr-1"},[_vm._v("today")]),_c('span',{staticClass:"d-none d-md-inline"},[_vm._v("Hoje")])],1),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","outlined":""}},'v-btn',attrs,false),on),[_c('app-icon',{staticClass:"mr-md-2 mr-0"},[_vm._v(" "+_vm._s(_vm.typeToLabel[_vm.type].icon)+" ")]),_c('span',{staticClass:"d-none d-md-inline"},[_vm._v(_vm._s(_vm.typeToLabel[_vm.type].label))]),_c('v-icon',{staticClass:"d-none d-md-inline",attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.type = 'month'}}},[_c('v-list-item-action',[_c('app-icon',[_vm._v(" calendar_month ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Mês ")])],1)],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'week'}}},[_c('v-list-item-action',[_c('app-icon',[_vm._v("calendar_view_week")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Semana ")])],1)],1),_c('v-list-item',{on:{"click":function($event){_vm.type = '4day'}}},[_c('v-list-item-action',[_c('app-icon',[_vm._v(" table_chart ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" 4 dias ")])],1)],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'day'}}},[_c('v-list-item-action',[_c('app-icon',[_vm._v(" calendar_view_day")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Dia ")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6","md":"2","order":"2","order-md":"3"}},[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":function($event){return _vm.openSessionForm()}}},[_c('app-icon',{staticClass:"mr-1"},[_vm._v("add")]),_vm._v(" Nova sessão")],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-calendar',{directives:[{name:"touch",rawName:"v-touch",value:({
              left: function () { return _vm.next(); },
              right: function () { return _vm.prev(); },
            }),expression:"{\n              left: () => next(),\n              right: () => prev(),\n            }"}],ref:"calendar",attrs:{"locale":"pt-BR","color":"primary","events":_vm.events,"event-color":_vm.getEventColor,"event-text-color":_vm.getEventTextColor,"type":_vm.type,"first-interval":6,"interval-height":"48","interval-count":"16","short-intervals":false,"event-more":false,"event-ripple":false},on:{"click:date":function($event){return _vm.handleAddEvent($event)},"click:event":function($event){return _vm.handleShowEvent($event)},"contextmenu:time":function($event){return _vm.handleAddEvent($event)},"change":function($event){return _vm.handleCalendarChange($event)},"mousedown:event":_vm.startDrag,"mousedown:day":_vm.startTime,"mousedown:time":_vm.startTime,"mousemove:day":_vm.mouseMoveDay,"mousemove:time":_vm.mouseMove,"mouseup:time":function($event){return _vm.endDrag($event)}},nativeOn:{"mouseleave":function($event){return _vm.cancelDrag($event)}},scopedSlots:_vm._u([{key:"day",fn:function(ref){
            var date = ref.date;
return [(!_vm.hasEventsOn(date))?_c('div',{staticStyle:{"min-height":"80px"}}):_vm._e()]}},{key:"interval",fn:function(ref){
            var time = ref.time;
return [(time == '06:00')?_c('div',{staticStyle:{"background-color":"#f7f7f7","height":"48px"}}):_vm._e()]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}})],1)],1)],1)],1),_c('StoreSessionForm',{ref:"StoreSessionForm",on:{"store":function($event){return _vm.handleStore()}}}),_c('SessionDialog',{ref:"SessionDialog",on:{"store":function($event){return _vm.handleStore()}}}),_c('RescheduleSessionForm',{ref:"RescheduleSessionForm",on:{"store":function($event){return _vm.handleStore()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }